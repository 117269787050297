import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';

import '../styles/index.scss';

const defaultTitle = 'BVOG';
const defaultDescription = 'BVOG Description'; // TODO: replace this text to real default description text

const Layout = ({ title, description, twitterCard, imageURL, children }) => {
  const seoTitle = title || defaultTitle;
  const seoDescription = description || defaultDescription;
  const seoTwitterCard = twitterCard || 'summary';
  const seoHttpImageURL = `http://www.datocms-assets.com${imageURL}`;
  const seoHttpsImageURL = `https://www.datocms-assets.com${imageURL}`;

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
              fallbackSeo {
                title
                description
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet>
            <title>{seoTitle}</title>
            <meta name="title" content={seoTitle} />
            <meta name="description" content={seoDescription} />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:description" content={seoDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.bvog.at/" />
            <meta property="og:site_name" content={data.datoCmsSite.globalSeo.siteName} />
            {imageURL && <meta property="og:image" content={seoHttpImageURL} />}
            {imageURL && <meta property="og:image:secure_url" content={seoHttpsImageURL} />}
            <meta name="og:email" content="post@bvog.at" />

            <meta name="twitter:card" content={seoTwitterCard} />
            <meta name="twitter:title" content={seoTitle} />
            <meta name="twitter:description" content={seoDescription} />
            <meta name="twitter:site" content="https://www.bvog.at/" />
            {imageURL && <meta name="twitter:image" content={seoHttpsImageURL} />}
            {/* <meta name="twitter:creator”" content="" /> */}
          </Helmet>
          <div className="main__layout">
            <div>
              <Header />
              <main>{children}</main>
            </div>

            <Footer />
          </div>
        </>
      )}
    />
  );
};

export default Layout;
