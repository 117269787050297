import React, { Component } from 'react';
import { Link, navigate, graphql, StaticQuery } from 'gatsby';
import { Location } from '@reach/router';
import queryString from 'query-string';
import Scrollbar from 'react-scrollbars-custom';

const hamburgerIcon = '/images/icons/hamburger.svg';
const searchIcon = '/images/icons/search.svg';
const closeIcon = '/images/icons/close.svg';

class Header extends Component {
  state = {
    isOpenMenu: false,
    isOpenSearch: false,
    searchQuery: ''
  };

  componentDidMount() {
    const { search } = this.props;

    if (search.query) {
      this.setState({ searchQuery: search.query });
      this.searhRequest(search.query);
    }
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props;

    // update search input field
    if (prevProps.search.query !== search.query) {
      this.setState({ searchQuery: search.query });
    }
  }

  toggleMenu(item) {
    if (item === 'menu') {
      this.setState(prevState => ({
        isOpenMenu: !prevState.isOpenMenu,
        isOpenSearch: false
      }));
    } else if ((item = 'search')) {
      this.setState(prevState => ({
        isOpenMenu: false,
        isOpenSearch: !prevState.isOpenSearch
      }));
    }
  }

  onKeyPress = e => {
    const { searchQuery } = this.state;

    if (e.which === 13) {
      this.searhRequest(searchQuery);
    }
  };

  handleChange = e => {
    const value = e.target.value;

    this.setState({ searchQuery: value });
  };

  handleClick = () => {
    const { searchQuery } = this.state;

    this.searhRequest(searchQuery);
  };

  searhRequest = value => {
    navigate(`/search?query=${value}`, {
      state: {
        searchQuery: value
      }
    });
  };

  render() {
    const { isOpenMenu, isOpenSearch, searchQuery } = this.state;
    const { data } = this.props;

    return (
      <div className="header">
        <div className="main__header">
          <div className="container">
            <div className="hamburger" onClick={() => this.toggleMenu('menu')}>
              <img src={isOpenMenu ? closeIcon : hamburgerIcon} alt="Menu" />
            </div>
            <div className="logo">
              <Link to="/">
                <div>
                  BILDUNGSVEREIN <br /> OFFENE GESELLSCHAFT
                </div>
              </Link>
            </div>
            <div className="social">
              <div onClick={() => this.toggleMenu('search')}>
                <img src={isOpenSearch ? closeIcon : searchIcon} alt="search" />
              </div>
              <div>
                <a href="https://www.facebook.com/bvog.at">
                  <img src="/images/icons/facebook.svg" alt="facebook" />
                </a>
              </div>
              <div>
                <a href="https://www.youtube.com/channel/UCQ5mvMxkJvfuBiIwLys2OjQ">
                  <img src="/images/icons/youtube.svg" alt="youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {isOpenSearch && (
          <div className="header__menu">
            <div className="container">
              <div className="header__menu__search__wrapper">
                <input
                  type="text"
                  placeholder="Suche..."
                  onChange={this.handleChange}
                  onKeyPress={this.onKeyPress}
                  value={searchQuery}
                />
                <img onClick={this.handleClick} src="/images/icons/search_lg.svg" alt="search" />
              </div>
            </div>
          </div>
        )}

        {isOpenMenu && (
          <div className="header__menu">
            <div className="container">
              <div className="header__menu__wrapper">
                <div className="header__menu__wrapper__list">
                  <nav>
                    <li>
                      <Link to="/">Startseite</Link>
                    </li>
                    <li>
                      <Link to="/offener-salon">#deroffenesalon</Link>
                    </li>
                    <li>
                      <Link to="/veranstaltungen">Veranstaltungen</Link>
                    </li>
                    <li>
                      <Link to="/schulungen">Schulungen</Link>
                    </li>
                    <li>
                      <Link to="/stipendien">Stipendien</Link>
                    </li>
                    <li>
                      <Link to="/about">Über uns</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Kontakt</Link>
                    </li>
                  </nav>
                </div>
                <div className="header__menu__wrapper__tags">
                  <Scrollbar
                    thumbYProps={{ className: 'scrollbarCustom__thumb' }}
                    trackYProps={{ className: 'scrollbarCustom__track' }}
                    style={{ height: 485 }}
                  >
                    <nav>
                      <Link to="/themenschwerpunkte" className="header__menu__wrapper__tags__link">
                        Themenschwerpunkt
                      </Link>
                      {data.allDatoCmsThemenschwerpunkte.edges.map(({ node }) => (
                        <li key={node.id}>
                          <Link to={`/themenschwerpunkte/${node.link}`}>#{node.link}</Link>
                        </li>
                      ))}
                      <Link to="/osterreich-bilanz" className="header__menu__wrapper__tags__link">
                        Bilanzen
                      </Link>
                      {data.allDatoCmsOsterreichbilanzen.edges.map(({ node }) => (
                        <li key={node.id}>
                          <Link to={`/osterreich-bilanz/${node.link}`}>#{node.link}</Link>
                        </li>
                      ))}
                      <Link to="/" className="header__menu__wrapper__tags__link">
                        Arbeitsweise
                      </Link>
                      {data.allDatoCmsKategorie.edges.map(({ node }) => (
                        <li key={node.id}>
                          <Link to={`/${node.link}`}>#{node.link}</Link>
                        </li>
                      ))}
                    </nav>
                  </Scrollbar>
                </div>
                <div className="header__menu__wrapper__posts">
                  {data.allDatoCmsBeitragBvogOfakt.edges.map(({ node }) => (
                    <Link to={`/beitraege/${node.link}`} key={node.id}>
                      <div className="post-card">
                        <img
                          src={node.bvogMainPicture && node.bvogMainPicture.url}
                          alt={node.beitragsname}
                        />
                        <div className="post-card__label">
                          <div className="post-card__label__wrapper">
                            <h2 className="title title--white">{node.bvogTitel}</h2>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const WithLocationHeader = props => (
  <Location>
    {({ location, navigate }) => (
      <Header
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
);

export default props => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allDatoCmsThemenschwerpunkte {
          edges {
            node {
              id
              link
            }
          }
        }
        allDatoCmsOsterreichbilanzen {
          edges {
            node {
              id
              link
            }
          }
        }
        allDatoCmsKategorie {
          edges {
            node {
              id
              link
            }
          }
        }

        allDatoCmsBeitragBvogOfakt(
          filter: { bvog: { eq: true } }
          limit: 2
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              ...DatoBeitragFields
            }
          }
        }
      }
    `}
    render={data => <WithLocationHeader data={data} {...props} />}
  />
);
