import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="main__footer">
    <Link to="/newsletter" className="newsletter">
      <div>
        <img src="/images/icons/mail.svg" alt="Newsletter" />
        <div>Newsletter</div>
      </div>
    </Link>

    <div className="policy">
      <Link to="/impressum">IMPRESSUM</Link> / <Link to="/datenschutz">DATENSCHUTZ</Link>
    </div>
  </footer>
);

export default Footer;
